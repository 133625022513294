<template>
  <v-card>
    <v-toolbar class="mb-2" color="primary" dark flat>
      <v-icon class="mr-5">mdi-link-variant</v-icon>
      <v-toolbar-title>Users Linker</v-toolbar-title>
      <v-spacer />
      <v-btn dark icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-row class="px-5 py-2">
      <v-col cols="12" sm="6" md="4">
        <auto-complete-users
          label="Virtuous' contact"
          v-model="contact"
          virtuous
          @change="updateContact"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <auto-complete-users
          label="Billfish's users"
          v-model="user"
          @change="updateUser"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-btn
          color="primary"
          :disabled="!user || !contact"
          @click="linkAccounts"
        >
          CONNECT ACCOUNTS
        </v-btn>
      </v-col>
    </v-row>
    <v-card-text class="mt-2 pt-3">
      <v-data-iterator
        :items="siblings"
        item-key="id"
        :items-per-page="siblings.length"
        hide-default-footer
      >
        <template v-slot:no-data>
          <v-banner>
            Add users from both environments to link
          </v-banner>
        </template>
        <template v-slot:default="{ items }">
          <v-row>
            <v-col v-for="item in items" :key="item.id" cols="12" md="6" lg="4">
              <v-card>
                <v-card-title>
                  <h5>{{ getName(item) }}</h5>
                  <v-badge
                    color="primary"
                    :content="item.source"
                    class="badge"
                  />
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pa-0">
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          Email:
                          <v-chip
                            class="ml-1"
                            x-small
                            color="green white--text"
                            v-if="item['multipleEmail']"
                          >
                            MULTIPLE
                          </v-chip>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ item.email || defaultMsg }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          Phone Number:
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="getPhone(item)">
                          {{ getPhone(item) | VMask('(###) ### ####') }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-else>
                          {{ defaultMsg }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-content>
                        <v-list-item-title> Birthday: </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ format(item.birthday) || defaultMsg }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Membership Details</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          Level:
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ membership(item).level || defaultMsg }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-content>
                        <v-list-item-title> Expiration: </v-list-item-title>
                        <v-list-item-subtitle>
                          {{
                            format(membership(item).expirationDate) ||
                              defaultMsg
                          }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item three-line>
                      <v-list-item-content>
                        <v-list-item-title>
                          Address:
                          <v-chip
                            class="ml-1"
                            x-small
                            color="green white--text"
                            v-if="item['multipleAddress']"
                          >
                            MULTIPLE
                          </v-chip>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ address(item) }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-card-text>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import AutoCompleteUsers from '../../components/appComponents/AutoCompleteUsers.vue';
import { getAddress, getUserInfo } from '../../helpers/user';
import { formatDate } from '../../helpers/format';
import { mapActions } from 'vuex';
import {
  notifyConfirmation,
  notifyError,
  notifyMessage
} from '../../helpers/notifications';
export default {
  components: { AutoCompleteUsers },
  name: 'users-linker',
  props: {
    contactUser: {
      type: Object,
      default: null
    },
    userProfile: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    user: null,
    contact: null,
    siblings: [],
    defaultMsg: '-',
    overlay: false
  }),
  computed: {
    full() {
      return !this.$vuetify.breakpoint.smAndDown;
    }
  },
  watch: {
    contactUser: {
      handler: 'setContact',
      immediate: true
    },
    userProfile: {
      handler: 'setUser',
      immediate: true
    }
  },
  methods: {
    ...mapActions('users', ['connectAccounts']),
    membership(item) {
      return item?.membership || {};
    },
    setContact(contact) {
      this.siblings = [];
      this.contact = null;
      if (contact) {
        this.contact = contact;
        this.siblings.push({ ...this.contact, source: 'virtuous' });
      }
    },
    setUser(user) {
      this.siblings = [];
      this.user = null;
      if (user) {
        this.user = user;
        this.siblings.push({ ...this.user, source: 'billfish' });
      }
    },
    address(user) {
      return getAddress(user);
    },
    format(date) {
      return date ? formatDate(date) : '';
    },
    getName(item) {
      const { firstName, lastName, title, suffixName, middleName } = item || {};

      const group = [title, firstName, middleName, lastName, suffixName];

      const name = group.filter(str => String(str).trim()).join(' ');

      return name?.trim() || 'NA';
    },
    getPhone(item) {
      const { personalPhone, workPhone, homePhone, phone } = item || {};
      return personalPhone || workPhone || homePhone || phone;
    },
    updateUser() {
      this.siblings = this.siblings.filter(
        sibling => sibling.source !== 'billfish'
      );
      if (!this.user) {
        return;
      }
      this.siblings.push({ ...this.user, source: 'billfish' });
    },
    updateContact() {
      this.siblings = this.siblings.filter(
        sibling => sibling.source !== 'virtuous'
      );
      if (!this.contact) {
        return;
      }
      this.siblings.push({ ...this.contact, source: 'virtuous' });
    },
    linkAccounts() {
      notifyConfirmation(
        'You will sync this account with Virtuous',
        'Are you sure to proceed?',
        async () => {
          this.overlay = true;
          const { id: userId, _id: _userId } = this.user;
          const { virtuousId } = this.contact;
          const { email } = getUserInfo() || {};
          try {
            const response = await this.connectAccounts({
              userId: userId || _userId,
              contactId: virtuousId,
              executedBy: email
            });

            const { status } = response || {};
            if (status === 200) {
              this.$emit('done');
              notifyMessage('Successfully connected');
            } else {
              this.$emit('done');
              notifyError('Action not performed');
            }
          } catch {
            this.$emit('done');
            notifyError('Action not performed');
          }
          this.overlay = false;
        }
      );
    }
  }
};
</script>

<style scoped>
.badge {
  position: absolute;
  top: 2%;
  left: 2%;
}
</style>
