class CrewViewModel {
  constructor() {
    this.angler = {
      id: null,
      firstName: null,
      lastName: null,
      fullName: null,
      legacyId: null,
      memberId: null
    };
    this.captain = {
      id: null,
      firstName: null,
      lastName: null,
      fullName: null,
      legacyId: null,
      memberId: null
    };
    this.firstMate = {
      id: null,
      firstName: null,
      lastName: null,
      fullName: null,
      legacyId: null,
      memberId: null
    };
    this.secondMate = {
      id: null,
      firstName: null,
      lastName: null,
      fullName: null,
      legacyId: null,
      memberId: null
    };
    this.boat = {
      id: null,
      name: null
    };
  }
}

class ReleaseCaptureViewModel {
  constructor() {
    this.fish = {
      species: null,
      quantity: 0
    };

    this.capture = {
      ocean: null,
      notes: null,
      tournament: null,
      gear: null,
      bait: null,
      releases: []
    };
  }
}

const date = new Date();
date.setMinutes(date.getMinutes() - date.getTimezoneOffset());

class CaptureViewModel {
  constructor() {
    this.number = null;
    this.sharkEncounter = null;
    this.enteredDate = date.toISOString();
    this.createdDate = date.toISOString();
    this.priorTag = {
      havePriorTag: false,
      priorTagNumber: null
    };
    this.relLines = null;
    this.relLines0 = null;
    this.relNumLi = null;
    this.capture = {
      ocean: null,
      notes: null,
      tournament: {
        id: null,
        name: null
      },
      bait: {
        id: null,
        name: null
      },
      gear: {
        id: '60b5903584ad98f5e4d0c277',
        name: 'ROD AND REEL'
      },
      hook: {
        isRemoved: false,
        name: null
      },
      fish: { ...new FishTagModel() }
    };
  }
}

class FishTagModel {
  constructor() {
    this.specie = {
      id: null,
      name: null,
      isNonBillFish: null
    };
    this.condition = {
      id: null,
      name: null
    };
    this.length = {
      value: null,
      unit: null,
      determination: null,
      type: null
    };
    this.weight = {
      value: null,
      unit: null,
      determination: null,
      type: null
    };
    this.fight = {
      hours: null,
      minutes: null
    };
  }
}

class FishReleaseModel {
  constructor(id = null, acronym = null, name = null, qty = null) {
    this.id = id;
    this.acronym = acronym;
    this.name = name;
    this.qty = qty;
  }
}

class CoordinatesViewModel {
  constructor() {
    this.coordinates = {
      description: null,
      longitude: {
        degrees: null,
        minutes: null,
        hemisphere: null
      },
      latitude: {
        degrees: null,
        minutes: null,
        hemisphere: null
      }
    };
  }
}

class TournamentsViewModel {
  constructor() {
    this._id = null;
    this.legacyId = null;
    this.name = null;
    this.updatedBy = null;
    this.updatedDate = null;
  }

  init(data) {
    Object.keys(this).forEach(
      (item) => data[item] && (this[item] = data[item])
    );

    return { ...this };
  }
}

class BoatsViewModel {
  constructor() {
    this._id = null;
    this.legacyId = null;
    this.owner = {
      id: null,
      firstName: null,
      lastName: null
    };
    this.name = null;
    this.make = null;
    this.model = null;
    this.length = null;
    this.enteredBy = null;
    this.updatedBy = null;
    this.updatedDate = null;
  }

  init(data) {
    Object.keys(this).forEach((item) => {
      if (item === 'owner') {
        data?.owner?.id && (this.owner.id = data.owner.id);
        data?.owner?.firstName && (this.owner.firstName = data.owner.firstName);
        data?.owner?.lastName && (this.owner.lastName = data.owner.lastName);
      } else {
        data[item] && (this[item] = data[item]);
      }
    });

    if (data?.membership) {
      this['membership'] = data.membership;
    }

    return { ...this };
  }
}

class SpeciesViewModel {
  constructor() {
    this._id = null;
    this.acronym = null;
    this.name = null;
    this.nonBillFish = null;
    this.updatedBy = null;
    this.updatedDate = null;
  }

  init(data) {
    Object.keys(this).map((item) => data[item] && (this[item] = data[item]));

    return { ...this };
  }
}

class BaitsViewModel {
  constructor() {
    this._id = null;
    this.acronym = null;
    this.name = null;
    this.legacyId = null;
    this.updatedBy = null;
    this.updatedDate = null;
  }

  init(data) {
    Object.keys(this).map((item) => data[item] && (this[item] = data[item]));

    return { ...this };
  }
}

class GearsViewModel {
  constructor() {
    this._id = null;
    this.acronym = null;
    this.name = null;
    this.legacyId = null;
    this.updatedBy = null;
    this.updatedDate = null;
  }

  init(data) {
    Object.keys(this).map((item) => data[item] && (this[item] = data[item]));

    return { ...this };
  }
}

class TrophiesViewModel {
  constructor() {
    this._id = null;
    this.name = null;
    this.fisherName = null;
    this.fisherId = null;
    this.role = null;
    this.taggedDate = null;
  }

  init(data) {
    Object.keys(this).map((item) => data[item] && (this[item] = data[item]));

    return { ...this };
  }
}

class LocationsViewModel {
  constructor() {
    this._id = null;
    this.name = null;
    this.ocean = null;
    this.legacyId = null;
    this.updatedBy = null;
    this.updatedDate = null;
    this.coordinates = new CoordinatesViewModel().coordinates;
    this.approved = null;
  }

  init(data) {
    Object.keys(this).map((item) => data[item] && (this[item] = data[item]));

    return { ...this, approved: data['approved'] };
  }
}

class UserModel {
  constructor() {
    this.firstName = null;
    this.lastName = null;
    this.role = ['angler'];
    this.email = null;
    this.password = '123456abc';
    this.address = {
      line1: null,
      line2: null,
      city: null,
      state: null,
      zip: null,
      country: null
    };
    this.personalPhone = null;
    this.workPhone = null;
    this.homePhone = null;
    this.birthday = null;
    this.gender = null;
    this.shirtSize = null;
  }
}

export {
  CrewViewModel,
  CaptureViewModel,
  CoordinatesViewModel,
  ReleaseCaptureViewModel,
  TournamentsViewModel,
  BoatsViewModel,
  SpeciesViewModel,
  BaitsViewModel,
  GearsViewModel,
  TrophiesViewModel,
  FishTagModel,
  FishReleaseModel,
  UserModel,
  LocationsViewModel
};
