<template>
  <v-card :elevation="elevation">
    <v-card-title class="mb-4">
      {{ title }}
      <v-spacer v-if="showSearch" />
      <v-col cols="12" sm="auto">
        <v-text-field
          v-model="search"
          label="Search by name"
          v-if="showSearch"
          append-icon="mdi-magnify"
          clearable
          @input="clearPagination"
          @click:clear="search = null"
          dense
          hide-details
        />
      </v-col>
      <v-col cols="12" sm="auto" v-if="exportable && records.length">
        <v-spacer />
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              small
              v-bind="attrs"
              v-on="on"
              :loading="loadingExport"
            >
              Export
              <v-icon right dark>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list class="export-list">
            <v-list-item
              :key="'csv'"
              class="dropdown-item"
              @click="$emit('export', 'csv')"
              dense
            >
              <v-list-item-title>
                <v-icon left>mdi-file-delimited-outline</v-icon>
                CSV
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              :key="'xls'"
              class="dropdown-item"
              @click="$emit('export', 'excel')"
              dense
            >
              <v-list-item-title>
                <v-icon left>mdi-file-excel-outline</v-icon>
                Excel
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="auto" v-if="addUser">
        <v-spacer />
        <v-bottom-sheet
          v-model="sheet"
          persistent
          :fullscreen="full"
          :scrollable="full"
          v-if="addUser"
        >
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              :class="full && 'my-2'"
              color="primary"
              v-bind="attrs"
              v-on="on"
              :block="full"
              small
            >
              Add New User
            </v-btn>
          </template>
          <new-user ref="newUser" @close="userSubmitted" />
        </v-bottom-sheet>
      </v-col>
      <v-spacer />
      <div v-if="filterable">
        <slot name="dropdown"></slot>
      </div>
      <div v-if="showScrollBtns">
        <v-btn
          class="mr-16 elevated"
          fab
          color="primary"
          small
          @click="scrollLeft"
          right
          fixed
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn
          class="mr-5 elevated"
          fab
          color="primary"
          small
          @click="scrollRight"
          right
          fixed
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </v-card-title>
    <v-data-table
      :ripple="{ center: true }"
      v-model="selectedRows"
      :headers="headers"
      :items="records"
      single-select
      :item-key="itemKey"
      :loading="asyncLoading"
      :show-select="showSelect"
      :options.sync="options"
      :pageOptions.sync="trackPagination"
      :footer-props="footerOpts"
      :server-items-length="totalDocs"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      :show-expand="expandable"
      :hide-default-footer="hideFooter"
      :hide-default-header="hideHeader"
      :item-class="rowClass"
      :group-by="groupBy"
      @click:row="conditionalClick"
    >
      <template v-slot:header="{ props }">
        <slot name="header" :row="props"></slot>
      </template>
      <template v-slot:[`group.header`]="{ group, toggle }">
        <th
          colspan="100%"
          @click="toggle"
          :style="{ cursor: 'pointer', textAlign: 'center' }"
        >
          {{ group }}
        </th>
      </template>
      <template v-slot:[`item.virtuousId`]="{ item }">
        {{ item['virtuousId'] }}
        <br v-if="item['virtuousId']" />
        <v-chip
          color="orange lighten-2 px-1"
          text-color="white"
          label
          x-small
          class="font-weight-bold flex"
          v-if="item['archived']"
        >
          <span>Archived</span>
        </v-chip>

        <v-chip
          color="primary lighten-2 px-1"
          text-color="white"
          label
          x-small
          class="font-weight-bold flex"
          v-else-if="item['connectionId']"
        >
          <span>Synced</span>
        </v-chip>

        <v-chip
          color="red lighten-2 px-1"
          text-color="white"
          label
          x-small
          class="font-weight-bold flex"
          v-if="item['removed']"
        >
          <span>Not in system</span>
        </v-chip>
      </template>
      <template v-slot:[`item.memberId`]="{ item }">
        {{ item['memberId'] }} <br v-if="item['memberId']" />
        <v-chip
          color="orange lighten-2 px-1"
          text-color="white"
          label
          x-small
          class="font-weight-bold flex"
          v-if="item['archived']"
        >
          <span>Archived</span>
        </v-chip>

        <v-chip
          color="primary lighten-2 px-1"
          text-color="white"
          label
          x-small
          class="font-weight-bold flex"
          v-else-if="item['virtuousId']"
        >
          <span>Synced</span>
        </v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <slot name="list-item" :row="item"></slot>
      </template>
      <template v-slot:[`item.qualify`]="{ item }">
        <slot name="qualify" :row="item"></slot>
      </template>
      <template v-slot:[`item.fullName`]="{ item }">
        <slot name="full-name" :row="item"></slot>
      </template>
      <template v-slot:[`item.place`]="{ item }">
        <slot name="competition-place" :row="item"></slot>
      </template>
      <template v-slot:[`item.fullAddress`]="{ item }">
        <slot name="full-address" :row="item"></slot>
      </template>
      <template v-slot:[`item.captures`]="{ item }">
        <slot name="captures" :row="item"></slot>
      </template>
      <template v-slot:[`item.createdDate`]="{ item }">
        <span>{{ formatDate(item.createdDate) }}</span>
      </template>
      <template v-slot:[`item.dateCreated`]="{ item }">
        <span>{{ formatDate(item.dateCreated) }}</span>
      </template>
      <template v-slot:[`item.enteredDate`]="{ item }">
        <span>{{ formatDate(item.enteredDate) }}</span>
      </template>
      <template v-slot:[`item.taggedDate`]="{ item }">
        <span>{{ formatDate(item.taggedDate) }}</span>
      </template>
      <template v-slot:[`item.updatedDate`]="{ item }">
        <span>{{ formatDate(item.updatedDate) }}</span>
      </template>
      <template v-slot:[`item.membership.expirationDate`]="{ item }">
        <v-chip
          color="red lighten-2"
          text-color="white"
          label
          small
          class="font-weight-bold chip_width"
          v-if="isExpired(item.membership['expirationDate'])"
        >
          <span>{{ formatDate(item.membership['expirationDate']) }}</span>
        </v-chip>
        <v-chip
          color="green lighten-2"
          text-color="white"
          label
          small
          class="font-weight-bold chip_width"
          v-else-if="isExpired(item.membership['expirationDate']) !== null"
        >
          <span>{{ formatDate(item.membership['expirationDate']) }}</span>
        </v-chip>
        <span v-else>{{ formatDate(item.membership['expirationDate']) }}</span>
      </template>
      <template v-slot:[`item.FISH_DATE1`]="{ item }">
        <span>{{ formatDate(item.FISH_DATE1) }}</span>
      </template>
      <template v-slot:[`item.FISH_DATE2`]="{ item }">
        <span>{{ formatDate(item.FISH_DATE2) }}</span>
      </template>
      <template v-slot:[getBaitCtrl()]="{}">
        <span>{{ getBaitName() }}</span>
      </template>
      <template v-slot:[getGearCtrl()]="{}">
        <span>{{ getGearName() }}</span>
      </template>
      <template v-slot:[getTournamentCtrl()]="{}">
        <span>{{ getTournamentName() }}</span>
      </template>
      <template v-slot:[`item.locality`]="{ item }">
        <span>{{ getLocation(item) }}</span>
      </template>
      <template v-slot:[`item.boatName`]="{ item }">
        <span>{{ getBoatName(item) }}</span>
      </template>
      <template v-slot:[`item.owner`]="{ item }">
        <span>{{ `${item.owner.firstName} ${item.owner.lastName}` }}</span>
      </template>
      <template v-slot:[`item.birthday`]="{ item }">
        <span>{{ formatDate(item.birthday) }}</span>
      </template>
      <template v-slot:[`item.personalPhone`]="{ item }">
        <span>{{ item.personalPhone | VMask('(###) ### ####') }}</span>
      </template>
      <template v-slot:[`item.workPhone`]="{ item }">
        <span>{{ item.workPhone | VMask('(###) ### ####') }}</span>
      </template>
      <template v-slot:[`item.homePhone`]="{ item }">
        <span>{{ item.homePhone | VMask('(###) ### ####') }}</span>
      </template>
      <template v-slot:[`item.user.homePhone`]="{ item }">
        <span>{{ getUserPhone(item.user) | VMask('(###) ### ####') }}</span>
      </template>
      <template v-slot:[`item.phone`]="{ item }">
        <span>{{ extractNumbers(item.phone) }}</span>
      </template>
      <template v-slot:[`item.source`]="{ item }">
        <span>{{
          item.source ? String(item.source).toUpperCase() : 'TBF'
        }}</span>
      </template>
      <template v-slot:[`item.FISH`]="{ item }">
        <span>
          <p
            v-for="(fish, index) in formatFish(item.FISH)"
            :key="`${fish}_${index}`"
            class="mb-0"
          >
            {{ fish }}
          </p>
        </span>
      </template>
      <template
        v-slot:[`footer.page-text`]="{ pageStart, pageStop, itemsLength }"
      >
        <div v-show="!!itemsLength">
          {{ pageStart }} - {{ pageStop }} of {{ itemsLength }}
        </div>
        <div v-show="!totalDocs">No records found</div>
      </template>
      <template v-slot:[`item.picture`]="{ item }">
        <v-avatar size="64" rounded @click="() => downloadPicture(item)">
          <v-img
            v-if="item.picture"
            :lazySrc="billfishDefaultImg"
            :src="item.picture"
          />
          <span v-else> - </span>
        </v-avatar>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import {
  fixTimezone,
  formatDate,
  formatPhone,
  notifyConfirmation
} from '../../helpers/handler';
import { billfishDefaultImg } from '../../helpers/constants';
import NewUser from '../../views/users/forms/sections/NewUser.vue';
import moment from 'moment';
export default {
  components: { NewUser },
  name: 'user-list-view',
  props: {
    title: String,
    headers: Array,
    records: Array,
    asyncLoading: Boolean,
    showSelect: Boolean,
    query: Object,
    footerOptions: Object,
    totalDocs: Number,
    serverItemsLength: Number,
    itemKey: {
      type: [String, Number]
    },
    exportable: Boolean,
    searchable: Boolean,
    singleExpand: {
      type: Boolean,
      default: true
    },
    expandable: Boolean,
    hideFooter: {
      type: Boolean,
      default: false
    },
    hideHeader: {
      type: Boolean,
      default: false
    },
    groupBy: String,
    addUser: Boolean,
    scrollable: {
      type: Boolean,
      default: false
    },
    filterable: Boolean,
    elevation: {
      default: '2'
    }
  },
  data: () => ({
    search: '',
    options: {},
    model: null,
    selectedRows: [],
    expanded: [],
    openConfirm: false,
    loadingExport: false,
    sheet: false
  }),
  computed: {
    billfishDefaultImg() {
      return billfishDefaultImg;
    },
    showSearch() {
      return (this.searchable && this.records?.length) || this.search?.length;
    },
    showScrollBtns() {
      return this.scrollable && this.records?.length;
    },
    footerOpts() {
      return {
        'items-per-page-options':
          this.title === 'Search Results' ||
          this.title === 'Recaptures completed'
            ? [30, 60, 90]
            : [10, 20, 50],
        ...this.footerOptions
      };
    },
    trackPagination() {
      this.$emit('onPaginate', {
        ...this.options,
        search: this.search
      });
      return this.options;
    },
    full() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    virtuousView() {
      return this.title.includes('Virtuous');
    }
  },
  watch: {
    selectedRows: {
      handler: 'emitRemove'
    }
  },
  methods: {
    getTournamentCtrl() {
      return this.item?.capture?.tournament?.name
        ? `item.capture.tournament.name`
        : 'none';
    },
    getGearCtrl() {
      return this.item?.capture?.gear?.name ? `item.capture.gear.name` : 'none';
    },
    getBaitCtrl() {
      return this.item?.capture?.bait?.name ? `item.capture.bait.name` : 'none';
    },
    getTournamentName() {
      const name = this.item?.capture?.tournament?.name;
      return name || name !== 'NULL' ? name : '';
    },
    getGearName() {
      const name = this.item?.capture?.gear?.name;
      return name || name !== 'UNKNOWN' ? name : '';
    },
    getBaitName() {
      const name = this.item?.capture?.bait?.name;
      return name || name !== 'UNKNOWN' ? name : '';
    },
    getLocation(item) {
      const { coordinates, locality } = item || {};
      return locality || coordinates?.description || '';
    },
    getBoatName(item) {
      const { boatName, boat } = item || {};
      return boatName || boat?.name || '';
    },
    formatDate(value) {
      const fixedDate = fixTimezone(value);
      return formatDate(fixedDate);
    },
    rowClass: function (item) {
      return item?.disqualified ? 'disabled' : '';
    },
    conditionalClick(item) {
      this.$emit('onClick', item);
    },
    clearPagination() {
      this.options.page = 1;
    },
    formatStr(str) {
      return str === 'NULL' || str === 'UNKNOWN' ? '' : str;
    },
    openAddItemDialog() {
      this.$emit('onClick', null);
    },
    emitRemove() {
      this.$emit('remove', this.selectedRows);
    },
    clearSearchAndRemove() {
      this.search = undefined;
      this.selectedRows = [];
    },
    resetParams() {
      this.search && (this.search = '');
      this.options = { ...this.options, page: 1 };
    },
    userSubmitted() {
      this.sheet = false;
      this.$emit('reload');
    },
    scrollLeft() {
      let content = document.querySelector('.v-data-table__wrapper');
      if (content?.scrollWidth) {
        content.scrollLeft -= content.scrollWidth / 2;
      }
    },
    scrollRight() {
      let content = document.querySelector('.v-data-table__wrapper');
      if (content?.scrollWidth) {
        content.scrollLeft += content.scrollWidth / 2;
      }
    },
    formatFish(fish) {
      const fishes = fish?.split('\n') || [];

      if (!fishes.length) {
        return [fish];
      }

      return fishes;
    },
    extractNumbers(phone) {
      return formatPhone(phone);
    },
    isExpired(value) {
      if (!value) {
        return null;
      }

      const date = moment(value);
      if (date.isValid()) {
        return date.isBefore(moment());
      }

      return null;
    },
    getUserPhone(user) {
      const { homePhone } = user || {};
      return homePhone;
    },
    downloadPicture(record) {
      const { picture, id, _id, number } = record || {};

      if (!picture) {
        return;
      }

      notifyConfirmation(
        '',
        `Do you want to download the ${number} record's picture?`,
        () => {
          var a = document.createElement('a');
          a.href = picture;
          a.download = `${number || id || _id} - picture.png`;
          a.click();
        },
        () => {},
        'Download'
      );
    },
    ...mapActions('entry', ['getAllEntries'])
  }
};
</script>
<style lang="css">
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.v-text-field__details {
  min-height: 0;
}
.v-card__title {
  padding: 16px 16px 0 16px;
}
.dropdown-item {
  cursor: pointer;
  min-height: 30px;
}
.dropdown-item:hover {
  background-color: aliceblue;
}
.export-list {
  padding: 4px 0;
}
.disabled {
  background-color: rgba(0, 0, 0, 0.3);
  color: gray;
}
.elevated {
  z-index: 999;
}

.chip_width {
  width: 100%;
  text-align: center !important;
}

.chip_width .v-chip__content {
  width: 100%;
}

.chip_width .v-chip__content span {
  width: 100%;
}
</style>
