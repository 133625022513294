<template>
  <v-card>
    <v-dialog
      v-model="linkView"
      scrollable
      persistent
      max-width="860px"
      :fullscreen="full"
    >
      <users-linker
        @close="closeLinkView"
        :userProfile="contact"
        @done="connectionDone"
      ></users-linker>
    </v-dialog>
    <v-toolbar flat dark color="primary" max-height="100px" v-if="!hideToolbar">
      <v-btn dark icon @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        {{ loading ? 'Loading...' : title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-progress-linear
      indeterminate
      color="primary"
      v-if="loading"
      striped
      height="5"
    ></v-progress-linear>
    <v-card-text>
      <v-row v-if="input.memberId">
        <v-col cols="12">
          {{ 'Membership ' + (isBefore ? 'expired on' : 'expires') }}
          {{ expirationDate | moment('dddd, MMMM Do YYYY') }}
        </v-col>
      </v-row>
      <!-- <v-row class="py-0" v-if="hideToolbar">
        <v-col cols="12" class="py-0">
          <b>TBF Reward Selection:</b> {{ rewardConfigSelected }}
        </v-col>

        <v-col cols="12" class="py-0" v-if="input?.rewardsConfig?.notes">
          <b>Note:</b> {{ input.rewardsConfig.notes }}
        </v-col>
      </v-row> -->
      <v-form ref="userForm" :class="!full && 'mt-5'" :disabled="loading">
        <v-row>
          <v-col cols="12" sm="6" md="3">
            <v-text-field
              label="* First name"
              :rules="rule"
              v-model="input.firstName"
              outlined
              dense
              clearable
              :disabled="!!input['virtuousId']"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-text-field
              label="Middle name"
              v-model="input.middleName"
              outlined
              dense
              clearable
              :disabled="!!input['virtuousId']"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-text-field
              label="* Last name"
              :rules="rule"
              v-model="input.lastName"
              outlined
              dense
              clearable
              :disabled="!!input['virtuousId']"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-text-field
              label="Suffix name"
              v-model="input.suffixName"
              outlined
              dense
              clearable
              :disabled="!!input['virtuousId']"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="3">
            <v-text-field
              label="Personal Phone"
              v-model="input.personalPhone"
              v-mask="phoneNumberMask"
              :rules="phoneRule"
              outlined
              dense
              clearable
              :disabled="!!input['virtuousId']"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="3">
            <v-text-field
              label="Work Phone"
              v-model="input.workPhone"
              v-mask="phoneNumberMask"
              :rules="phoneRule"
              outlined
              dense
              clearable
              :disabled="!!input['virtuousId']"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="3">
            <v-text-field
              label="Home Phone"
              v-model="input.homePhone"
              v-mask="phoneNumberMask"
              :rules="phoneRule"
              outlined
              dense
              clearable
              :disabled="!!input['virtuousId']"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-text-field
              label="Email"
              v-model="input.email"
              :rules="emailRule"
              outlined
              dense
              clearable
              :disabled="!!input['virtuousId']"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" md="3">
            <date-picker
              v-model="input.birthday"
              label="Birthday"
              @clear="input.birthday = null"
            />
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-text-field
              label="Member ID"
              v-model="input.memberId"
              outlined
              dense
              clearable
              :disabled="!!input['virtuousId']"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-autocomplete
              :items="genderOpts"
              label="Gender"
              v-model="input.gender"
              outlined
              dense
              clearable
              hide-details
            />
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-text-field
              label="Shirt Size"
              v-model="input.shirtSize"
              outlined
              dense
              clearable
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Address (line 1)"
              v-model="input.address.line1"
              outlined
              dense
              clearable
              hide-details
              :disabled="!!input['virtuousId']"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              label="Address (line 2)"
              v-model="input.address.line2"
              outlined
              dense
              clearable
              hide-details
              :disabled="!!input['virtuousId']"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" md="3">
            <v-text-field
              label="City"
              v-model="input.address.city"
              outlined
              dense
              clearable
              hide-details
              :disabled="!!input['virtuousId']"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-autocomplete
              :items="states"
              label="State"
              item-text="name"
              item-value="acronym"
              v-model="input.address.state"
              outlined
              dense
              clearable
              hide-details
              :disabled="!!input['virtuousId']"
            />
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-text-field
              label="ZIP"
              v-model="input.address.zip"
              outlined
              dense
              clearable
              hide-details
              :disabled="!!input['virtuousId']"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="3">
            <v-text-field
              label="Country"
              v-model="input.address.country"
              outlined
              dense
              clearable
              hide-details
              :disabled="!!input['virtuousId']"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              label="Notes"
              v-model="input.notes"
              outlined
              dense
              clearable
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6">
            <v-checkbox
              v-model="input.multipleEvents"
              label="Allow multiple events entry"
              hide-details
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-checkbox
              v-model="input.doNotMail"
              label="Do not mail"
              hide-details
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-checkbox
              v-model="input.doNotNotify"
              label="Don't notify"
              hide-details
            />
          </v-col>
        </v-row>
      </v-form>
      <v-banner color="light-blue lighten-5" v-if="input['virtuousId']">
        <v-icon slot="icon" color="primary" size="36"> mdi-link-lock </v-icon>
        This profile has been bonded to a Virtuous' account. Contact ID:
        {{ input['virtuousId'] }}
        <v-chip
          color="orange lighten-2 px-1"
          text-color="white"
          label
          x-small
          class="font-weight-bold"
          v-if="input['archived']"
        >
          <span>Archived</span>
        </v-chip>
      </v-banner>
    </v-card-text>
    <v-toolbar flat dark color="primary" dense>
      <div v-if="edit">
        <v-btn
          text
          link
          small
          @click="unlinkContact"
          v-if="input['virtuousId']"
        >
          <v-icon>mdi-link-off</v-icon>
          Linked to Virtuous, click here to unlink
        </v-btn>
        <v-btn text link small @click="openLinkView" v-else>
          <v-icon>mdi-link</v-icon>
          Link to a Virtuous' contact
        </v-btn>
      </div>
      <v-spacer />
      <v-btn
        small
        @click="submit"
        :disabled="loading"
        color="white"
        class="primary--text"
      >
        {{ save }}
      </v-btn>
    </v-toolbar>
  </v-card>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import DatePicker from '../../../../components/appComponents/DatePicker.vue';
import {
  GENDER_OPTIONS,
  notifyMessage,
  notifyConfirmation,
  getUserInfo
} from '../../../../helpers/handler';
import { UserModel } from '../../viewmodels';
import UsersLinker from '../../UsersLinker.vue';
export default {
  components: { DatePicker, UsersLinker },
  name: 'new-user',
  props: {
    hideToolbar: {
      type: Boolean,
      default: false
    },
    userId: {
      type: String,
      default: null
    },
    email: {
      type: String,
      default: null
    }
  },
  data: () => ({
    input: new UserModel(),
    rule: [(v) => !!v || 'Required'],
    emailRule: [(v) => !v || /.+@.+\..+/.test(v) || 'E-mail must be valid'],
    phoneRule: [
      (v) => !v || v?.length === 14 || 'Phone number must have 10 digits'
    ],
    states: [],
    save: 'Save',
    loading: false,
    title: '',
    genderOpts: GENDER_OPTIONS,
    phoneNumberMask: '(###) ### ####',
    contact: null,
    linkView: false
  }),
  computed: {
    full() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    edit() {
      const { _id, id } = this.input || {};
      return !!(_id || id);
    },
    isBefore() {
      const { expirationDate } = this.input?.membership || {};
      if (!expirationDate) return false;
      const date = moment(expirationDate, moment.ISO_8601, true);
      if (!date.isValid()) return false;
      return moment(expirationDate).isBefore(moment());
    },
    expirationDate() {
      return this.input?.membership?.expirationDate || null;
    },
    rewardConfigSelected() {
      const { receiveCertifications, receiveReward, updatedAt } =
        this.input?.rewardsConfig || {};
      if (!updatedAt) {
        return 'Not selected';
      }

      if (!receiveReward) {
        return 'No reward including certificate and t-shirt';
      }

      if (!receiveCertifications) {
        return 'No certificate or report';
      }

      return 'I want to receive certifications and rewards';
    }
  },
  async mounted() {
    await this.getStates();
  },
  watch: {
    // userId: {
    //   handler: 'init'
    // },
    email: {
      handler: 'setEmail',
      immediate: true
    }
  },
  methods: {
    ...mapActions('users', [
      'addUser',
      'getUser',
      'saveUserInfo',
      'checkEmail',
      'checkPersonalPhone',
      'connectAccounts',
      'disconnectAccounts'
    ]),
    setEmail(email) {
      if (email) {
        this.input = { ...this.input, email };
      }
    },
    async submit() {
      const validForm = this.$refs.userForm && this.$refs.userForm.validate();
      if (validForm) {
        this.loading = true;
        this.save = 'Saving...';

        if (this.edit) {
          const response = await this.saveUserInfo(this.input);
          const { success } = response?.data || {};
          notifyMessage(
            success ? 'User successfully updated' : 'Something went wrong'
          );
          this.loading = false;
          this.save = 'Save';
          this.close();
          return;
        }

        const { email, personalPhone } = this.input || {};
        if (email) {
          const { status } = (await this.checkEmail({ email })) || {};
          if (status === 200) {
            notifyMessage('Email already in use. Please enter a new one');
            this.loading = false;
            this.save = 'Save';
            return;
          }
        }

        if (personalPhone) {
          const { status } =
            (await this.checkPersonalPhone({ personalPhone })) || {};
          if (status === 200) {
            notifyMessage(
              'Personal phone already in use. Please enter a new one'
            );
            this.loading = false;
            this.save = 'Save';
            return;
          }
        }

        const response = await this.addUser(this.input);
        const { success } = response?.data || {};
        notifyMessage(
          success ? 'User successfully created' : 'User already exists'
        );
        this.loading = false;
        this.save = 'Save';
        this.close();
      }
    },
    async init(id) {
      this.$refs.userForm && this.$refs.userForm.reset();
      this.input = new UserModel();

      if (!id) {
        this.title = 'Add New User';
        return;
      }

      if (id.includes('@')) {
        this.input = { ...this.input, email: id };
        this.title = 'Add New User';
        return;
      }

      this.title = 'Edit User';
      this.loading = true;
      const { data } = await this.getUser({ id });
      this.loading = false;
      if (!data) {
        notifyMessage('User not found');
        this.close();
        return;
      }
      this.phoneNumberMask = '';
      data?.address?.state?.length && this.getStates(data.address.state[0]);
      data && (this.input = { ...this.input, ...data });
      this.$nextTick(() => {
        this.phoneNumberMask = '(###) ### ####';
      });
    },
    async getStates(name = '') {
      const payload = { name, item: 'states', page: 1, limit: 10 };
      const { data } = await this.getItems(payload);
      data?.docs && (this.states = data.docs);
    },
    close() {
      this.$refs.userForm && this.$refs.userForm.reset();
      this.$emit('close');
    },
    linkContact() {
      notifyConfirmation(
        'You will sync this account with Virtuous',
        'Are you sure to proceed?',
        async () => {
          const { id: userId, _id: user_Id } = this.input || {};
          const { id: contactId, _id: contact_Id } = this.contact || {};
          const { email } = getUserInfo() || {};
          const response = await this.connectAccounts({
            userId: userId || user_Id,
            contactId: contactId || contact_Id,
            executedBy: email
          });

          const { status } = response || {};
          if (status === 200) {
            this.close();
            notifyMessage('Successfully connected');
          }
          this.contact = null;
        }
      );
    },
    unlinkContact() {
      notifyConfirmation(
        'You will disconnect this account from Virtuous sync',
        'Are you sure to proceed?',
        async () => {
          const { id, _id } = this.input || {};
          const { email } = getUserInfo() || {};
          const response = await this.disconnectAccounts({
            userId: id || _id,
            executedBy: email
          });
          const { status } = response || {};
          if (status === 200) {
            this.connectionDone();
            notifyMessage('Successfully disconnected');
          }
        }
      );
    },
    openLinkView() {
      this.contact = this.input;
      this.linkView = true;
    },
    closeLinkView() {
      this.contact = null;
      this.linkView = false;
    },
    connectionDone() {
      this.close();
      this.$emit('done');
    },
    ...mapActions('query', ['getItems'])
  }
};
</script>
